import createMuiTheme from "@material-ui/core/styles/createMuiTheme"

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#b9151b"
    }
  }
})
export default appTheme
