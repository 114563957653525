export const GET_PRODUCT_DATA_LIST = "GET_PROCUT_LIST"
export const GET_CURRENT_PRODUCT_DATA = "GET_CURRENT_PRODUCT_DATA"
export const SET_CURRENT_PRODUCT_DATA = "SET_CURRENT_PRODUCT_DATA"

export const SET_CURRENT_NODE_DATA = "SET_CURRENT_NODE_DATA"

export const SET_SELECTED_MESH_DATA = "SET_SELECTED_MESH_DATA"

export const SET_CURRENT_MATERIAL_DATA = "SET_CURRENT_MATERIAL_DATA"
export const SELECTED_MESH_MATERIAL = "SELECTED_MESH_MATERIAL"

export const CURRENT_DRACO_VERSION = "CURRENT_DRACO_VERSION"
